import React from 'react'
import SbEditable from 'storyblok-react'
import useStoryblokBridge from '../../hooks/useStoryblokBridge'
import ExperiencePage from './ExperiencePage'

const ExperiencePageWithState = () => {
    const { content } = useStoryblokBridge()
    return (
        <SbEditable content={content}>
            <ExperiencePage {...content} />
        </SbEditable>
    )
}

export default ExperiencePageWithState
