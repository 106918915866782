import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import { sectionSpacingVariant, sectionContainerVariant, sectionBackgroundVariant } from '../../util/variants'
import BannerScript from './BannerScript'

const BannerSection = ({
    content,
}) => {
    const {
        snippet,
        spacing,
        container,
        spacingTop,
        background,
        spacingBottom,
    } = content

    return (
        <SbEditable content={content}>
            <section
                className={clsx(
                    sectionBackgroundVariant(background),
                    sectionSpacingVariant({ spacing, spacingTop, spacingBottom }),
                )}
            >
                <BannerScript
                    script={snippet}
                    className={clsx('flex justify-center', sectionContainerVariant(container))}
                />
            </section>
        </SbEditable>
    )
}

export default BannerSection
