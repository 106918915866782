import React from 'react'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import useCarousel from '@dashdot/react-hooks-slider'
import * as constants from '@connections/constants'
import { variant } from '../../util/index'
import Image from '../utils/Image'
import CarouselControls from './CarouselControls'

const {
    CONTAINER_EXTRA_SMALL: EXTRA_SMALL,
    CONTAINER_SMALL: SMALL,
    CONTAINER_DEFAULT: DEFAULT,
    BACKGROUND_WHITE,
    BACKGROUND_GRAY_LIGHT,
    BACKGROUND_GRAY,
    BACKGROUND_BLUE,
    BACKGROUND_BLACK,
    SECTION_SPACING_SMALL,
    SECTION_SPACING_MEDIUM,
    SECTION_SPACING_LARGE,
} = constants

const CarouselSection = ({
    images = [],
    background,
    container,
    spacing,
    anchorId,
    className,
}) => {
    const {
        position,
        hasPreviousSlide,
        hasNextSlide,
        handleNextSlide,
        handlePreviousSlide,
        slideRefs,
        containerRef,
        isDisabled,
        swipingProps,
    } = useCarousel()

    return (
        <section
            id={anchorId || undefined}
            data-testid="CarouselSection"
            className={clsx(
                'overflow-hidden relative flex justify-center',
                variant(spacing, {
                    [SECTION_SPACING_SMALL]: 'py-12',
                    [SECTION_SPACING_MEDIUM]: 'py-24',
                    [SECTION_SPACING_LARGE]: 'py-36',
                }),
                variant(background, {
                    [BACKGROUND_WHITE]: 'bg-white',
                    [BACKGROUND_GRAY_LIGHT]: 'bg-gray-50',
                    [BACKGROUND_GRAY]: 'bg-gray-100',
                    [BACKGROUND_BLACK]: 'bg-black',
                    [BACKGROUND_BLUE]: 'bg-blue',
                }),
                variant(container, {
                    [EXTRA_SMALL]: 'container-xs',
                    [SMALL]: 'container-sm',
                    [DEFAULT]: 'container',
                }),
                className
            )}
        >
            <motion.div
                ref={containerRef}
                animate={{ x: isDisabled || position === null ? 0 : `${position}%` }}
                transition={{ duration: 0.4, ease: 'easeOut' }}
                className={clsx(
                    'whitespace-nowrap flex flex-nowrap w-full touch-pan-y',
                    isDisabled ? 'justify-center' : 'cursor-move select-none',
                )}
                {...swipingProps}
            >
                {images.map((image, index) => (
                    <div
                        key={image.filename}
                        ref={(ref) => { slideRefs.current[index] = ref }}
                        className="pr-4 lg:pr-6 last:pr-0 flex-grow-0 flex-shrink-0 w-[365px] h-[288px] relative"
                    >
                        <div className="relative w-full h-full">
                            <Image
                                fill
                                maxWidth={640}
                                {...image}
                                draggable={false}
                                src={image.filename}
                                className="object-cover"
                            />
                        </div>
                    </div>
                ))}
            </motion.div>
            <CarouselControls
                isDisabled={isDisabled}
                hasNextSlide={hasNextSlide}
                onNextSlide={handleNextSlide}
                hasPreviousSlide={hasPreviousSlide}
                onPreviousSlide={handlePreviousSlide}
            />
        </section>
    )
}

export default CarouselSection
