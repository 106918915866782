import React from 'react'
import SbEditable from 'storyblok-react'
import useStoryblokBridge from '../../hooks/useStoryblokBridge'
import BlogPostPage from './BlogPostPage'

const BlogPostPageWithState = () => {
    const { content, first_published_at: publishedAt } = useStoryblokBridge()

    return (
        <SbEditable content={content}>
            <BlogPostPage {...content} publishedAt={publishedAt} />
        </SbEditable>
    )
}

export default BlogPostPageWithState
