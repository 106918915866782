import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import * as constants from '@connections/constants'
import Price from '../utils/Price'
import DynamicButton from './DynamicButton'
import CustomText from '../utils/CustomText'
import Button, { VARIANT_BLUE_FILLED } from '../utils/Button'
import Image, { createLoader } from '../utils/Image'
import { variant } from '../../util/index'
import {
    sectionBackgroundVariant,
    sectionContainerVariant,
    textColorVariant,
} from '../../util/variants'
import Video from '../utils/Video'
import {
    SCREEN_SIZE_MD as MD,
    SCREEN_SIZE_LG as LG,
    SCREEN_SIZE_XL as XL,
    SCREEN_SIZE_XXL as XXL,
} from '../../util/styles'

const {
    ALIGN_TOP_LEFT,
    ALIGN_TOP_RIGHT,
    ALIGN_BOTTOM_LEFT,
    ALIGN_BOTTOM_RIGHT,
    ALIGN_CENTER,
    ALIGN_CENTER_LEFT,
    ALIGN_CENTER_RIGHT,
    CONTAINER_SMALL,
} = constants

const HeadingDesktop = ({
    position,
    buttons = [],
    href,
    image,
    video,
    videoStartOffset,
    videoEndOffset,
    contentImage,
    contentImageMaxWidth,
    contentImageMaxHeight,
    title,
    titleColor,
    subTitle,
    subTitleColor,
    description,
    descriptionColor,
    price,
    hasArc,
    gradient,
    container = CONTAINER_SMALL,
    pricePrefix,
    background,
}) => {
    const hasVideo = video?.filename
    const hasImage = image?.filename
    const contentImageLoader = createLoader({ maxWidth: 1000 })
    return (
        <>
            <div
                className={clsx(
                    'flex h-full',
                    sectionContainerVariant(container),
                    variant(position, {
                        [ALIGN_TOP_LEFT]: 'items-start justify-start',
                        [ALIGN_TOP_RIGHT]: 'items-start justify-end',
                        [ALIGN_BOTTOM_LEFT]: 'items-end justify-start',
                        [ALIGN_BOTTOM_RIGHT]: 'items-end justify-end',
                        [ALIGN_CENTER]:
                            'items-center justify-center text-center',
                        [ALIGN_CENTER_LEFT]: 'items-center justify-start',
                        [ALIGN_CENTER_RIGHT]: 'items-center justify-end',
                    })
                )}
            >
                <div
                    className={clsx(
                        'flex flex-col z-1',
                        variant(position, {
                            [ALIGN_TOP_LEFT]: 'items-start mt-16',
                            [ALIGN_TOP_RIGHT]: 'items-start mt-16',
                            [ALIGN_BOTTOM_LEFT]: 'items-start mb-16',
                            [ALIGN_BOTTOM_RIGHT]: 'items-start mb-16',
                            [ALIGN_CENTER]: 'items-center -mt-16',
                            [ALIGN_CENTER_LEFT]: '-mt-32 lg:-mt-20',
                            [ALIGN_CENTER_RIGHT]: '-mt-20',
                        })
                    )}
                >
                    {contentImage?.src && (
                        <div
                            className="relative"
                            style={{
                                maxWidth:
                                    contentImageMaxWidth &&
                                    `${contentImageMaxWidth}px`,
                                maxHeight:
                                    contentImageMaxHeight &&
                                    `${contentImageMaxHeight}px`,
                            }}
                        >
                            <img
                                src={contentImageLoader({
                                    src: contentImage.src,
                                    width: contentImageMaxWidth || 700,
                                })}
                                {...contentImage}
                                className="mb-2"
                            />
                        </div>
                    )}
                    {subTitle && (
                        <h3
                            className={clsx(
                                'text-xl font-medium mb-2',
                                textColorVariant(subTitleColor)
                            )}
                        >
                            {subTitle}
                        </h3>
                    )}
                    {title && (
                        <CustomText
                            as="h1"
                            className={clsx(
                                'text-5xl font-medium mb-2',
                                textColorVariant(titleColor)
                            )}
                            customText={title}
                            customTextClassName="font-accent text-7xl"
                        />
                    )}
                    {price !== null && price !== '' && (
                        <h3 className="text-white text-xl font-medium mb-4 leading-none">
                            <Price price={price} prefix={pricePrefix} />
                        </h3>
                    )}
                    {description && (
                        <p
                            className={clsx(
                                'text-md font-medium mb-8 max-w-xl whitespace-pre-wrap',
                                textColorVariant(descriptionColor)
                            )}
                        >
                            {description}
                        </p>
                    )}
                    {buttons.length > 0 && (
                        <div className="flex flex-wrap gap-4">
                            {buttons.map((button) => (
                                <DynamicButton
                                    key={button._uid}
                                    content={button}
                                />
                            ))}
                        </div>
                    )}
                    {buttons.length === 0 && href && (
                        <div>
                            <Button
                                as="a"
                                href={href}
                                iconAfterName="arrow-right"
                                variant={VARIANT_BLUE_FILLED}
                            >
                                <Trans id="Discover" />
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <div
                className={clsx(
                    'absolute inset-0 object-cover h-full w-full -z-1 bg-black',
                    hasArc && 'has-bottom-arc'
                )}
            >
                {hasVideo && (
                    <Video
                        loop
                        muted
                        autoPlay
                        playsInline
                        src={video.filename}
                        endOffset={videoEndOffset}
                        startOffset={videoStartOffset}
                        screenSizesToRender={[MD, XL, LG, XXL]}
                        className="absolute h-full w-full inset-0 object-cover"
                    />
                )}
                {!hasVideo && hasImage && (
                    <Image
                        fill
                        priority
                        quality={70}
                        sizes="(max-width: 768px) 768px, (max-width: 1200px) 1200px, (max-width: 1600px) 1600px, 100vw"
                        {...image}
                        src={image.filename}
                        className="object-cover"
                        loading="eager"
                        fetchPriority="high"
                    />
                )}
            </div>
            {background && background !== '' && (
                <div
                    className={clsx(
                        'absolute -z-1 inset-0 opacity-80',
                        hasArc && 'has-bottom-arc',
                        sectionBackgroundVariant(background)
                    )}
                />
            )}
            {gradient && gradient !== 'none' && (
                <div
                    className={clsx(
                        'absolute -z-1',
                        hasArc && 'has-bottom-arc',
                        variant(gradient, {
                            small: 'heading-gradient-sm h-[14rem] bottom-0 left-0 w-full',
                            default: 'heading-gradient inset-0',
                            large: 'heading-gradient-lg inset-0',
                            extraLarge: 'heading-gradient-xl inset-0',
                        })
                    )}
                />
            )}
        </>
    )
}

export default HeadingDesktop
