/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import { Trans } from '@lingui/react'
import SbEditable from 'storyblok-react'
import Tag from '../utils/Tag'
import Icon from '../utils/Icon'
import Image from '../utils/Image'
import Price from '../utils/Price'
import CustomText from '../utils/CustomText'
import Button, { VARIANT_WHITE_OUTLINED } from '../utils/Button'
import { getEntityContentOverrides, getEntityContentPrice } from '../../util/entities'
import usePathnameLocale from '../hooks/usePathnameLocale'
import useLocalizedCity from '../hooks/useLocalizedCityDeprecated'

const BreakCard = ({
    content,
    className,
}) => {
    const {
        icons,
        buttonText,
        hideGradient,
        featuredImage,
        openInNewTab,
    } = content
    const locale = usePathnameLocale()
    const {
        tag,
        href,
        image,
        title,
        iataCode,
        destination,
        description,
    } = getEntityContentOverrides(content, content.break, locale)
    const price = getEntityContentPrice(content.break)
    const city = useLocalizedCity(iataCode)
    const finalDestination = destination || city.name
    return (
        <SbEditable content={content}>
            <Link
                href={href}
                draggable={false}
                target={openInNewTab ? '_blank' : undefined}
                className={
                    clsx(
                        'group block relative h-[350px] bg-gray-100 rounded-lg',
                        'md:h-[475px]',
                        className,
                    )
                }
            >
                {image?.filename && (
                    <Image
                        fill
                        quality={80}
                        maxWidth={750}
                        {...image}
                        draggable={false}
                        src={image.filename}
                        className="rounded-lg group-hover:opacity-80 object-cover"
                    />
                )}
                {featuredImage?.filename && (
                    <div className="absolute top-0 right-0 w-20 h-20">
                        <Image
                            fill
                            maxWidth={100}
                            draggable={false}
                            {...featuredImage}
                            src={featuredImage.filename}
                            className="rounded-tr-lg rounded-bl-lg object-cover"
                        />
                    </div>
                )}
                {tag.text && (
                    <div className="absolute top-6 left-6">
                        <Tag {...tag} />
                    </div>
                )}
                <div className="absolute bottom-6 inset-x-6 z-1">
                    <div className="flex items-center">
                        {icons && icons.length > 0 && (
                            <div className="grid grid-flow-col gap-x-2">
                                {
                                    icons.map((name) => (
                                        <Icon
                                            key={name}
                                            name={name}
                                            size="smaller"
                                            color="white"
                                        />
                                    ))
                                }
                            </div>
                        )}
                    </div>
                    {finalDestination !== null && (
                        <div className="uppercase text-xs text-white mt-4">
                            {finalDestination}
                        </div>
                    )}
                    {title && (
                        <CustomText
                            as="h3"
                            className="text-white text-2xl font-medium mt-2"
                            customText={title}
                        />
                    )}
                    {description && (
                        <p className="text-white text-sm mt-2 line-clamp-4">
                            {description}
                        </p>
                    )}
                    <div className="flex justify-between items-center mt-6">
                        {href && (
                            <Button
                                as="div"
                                variant={VARIANT_WHITE_OUTLINED}
                            >
                                {buttonText || <Trans id="Discover" />}
                            </Button>
                        )}
                        {price && (
                            <Price price={price} className="text-white text-sm" />
                        )}
                    </div>
                </div>
                {!hideGradient && (
                    <div className="absolute bottom-0 inset-x-0 rounded-b-lg h-3/4 card-gradient" />
                )}
            </Link>
        </SbEditable>
    )
}

export default BreakCard
