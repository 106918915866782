import React from 'react'
import {
    StringParam,
    withDefault,
    useQueryParams,
    encodeDelimitedArray,
    decodeDelimitedArray,
} from 'use-query-params'
import SbEditable from 'storyblok-react'
import { useLingui } from '@lingui/react'
import useStoryblokBridge from '../../hooks/useStoryblokBridge'
import QueryParamProvider from '../../providers/QueryParamProvider'
import TravelSearchPage from './TravelSearchPage'
import useTravelSearchPageQuery from './useTravelSearchPageQuery'
import useTravelSearchPageFilters from './useTravelSearchPageFilters'

const CommaArrayParam = {
    encode: (array) => encodeDelimitedArray(array, ','),
    decode: (arrayStr) => decodeDelimitedArray(arrayStr, ',')
}

const getSortOptions = (i18n) => [{
    value: 'numberOfDays:desc:int',
    label: i18n._(/*i18n*/'Most days'),
}, {
    value: 'price:asc:int',
    label: i18n._(/*i18n*/'Lowest price'),
}, {
    value: 'numberOfDays:asc:int',
    label: i18n._(/*i18n*/'Least days'),
}]

const TravelSearchPageWithState = () => {
    const { i18n } = useLingui()
    const sortOptions = getSortOptions(i18n)
    const [query, setQuery] = useQueryParams({
        months: CommaArrayParam,
        countryCode: StringParam,
        formulas: CommaArrayParam,
        themes: CommaArrayParam,
        vacations: CommaArrayParam,
        continentCodes: CommaArrayParam,
        sortBy: withDefault(StringParam, sortOptions[0].value),
    })
    const { content } = useStoryblokBridge()
    const {
        total,
        travels,
        hasMore,
        isFetching,
        isUpdating,
        onFetchMore,
        travelThemes,
        isFetchingMore,
        travelFormulas,
    } = useTravelSearchPageQuery(query, content.settings)
    const {
        filters,
        formState,
        selectedValues,
    } = useTravelSearchPageFilters(query, setQuery, content.settings, travelFormulas, travelThemes)

    return (
        <SbEditable content={content}>
            <TravelSearchPage
                content={content}
                hasMore={hasMore}
                filters={filters}
                travels={travels}
                sortBy={query.sortBy}
                isFetching={isFetching}
                isUpdating={isUpdating}
                onFetchMore={onFetchMore}
                sortOptions={sortOptions}
                filterFormState={formState}
                totalAmountOfTravels={total}
                selectedValues={selectedValues}
                isFetchingMore={isFetchingMore}
                onResetFilters={formState.destroy}
                onChangeSortBy={(value) => setQuery({ ...query, sortBy: value })}
            />
        </SbEditable>
    )
}

export default (props) => (
    <QueryParamProvider>
        <TravelSearchPageWithState {...props} />
    </QueryParamProvider>
)
