import React from 'react'
import { getLocaleRoute } from '../../util/routes'
import usePathnameLocale from '../hooks/usePathnameLocale'
import TextLink from './TextLink'

const LocaleLink = ({
    href,
    children,
    ...props
}) => {
    const locale = usePathnameLocale(true)
    const route = getLocaleRoute({ locale, pathname: href })

    return (
        <TextLink
            href={route}
            {...props}
        >
            {children}
        </TextLink>
    )
}

export default LocaleLink
