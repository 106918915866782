import { useMemo, useState } from 'react'
import * as constants from '@connections/constants'
import { getPersonPriceFromCharge } from '../../util/prices'
import { calculateAverageFareTravelTime } from '../../util/booking/flights'
import useSortingRules from './useSortingRules'

const {
    SORT_OPTION_CHEAPEST: CHEAPEST,
    SORT_OPTION_SHORTEST: SHORTEST,
    SORT_OPTION_RECOMMENDED: RECOMMENDED,
} = constants

const defaultSortingRule = (a, b) => getPersonPriceFromCharge(a.charge) - getPersonPriceFromCharge(b.charge)

const getSortedFares = (fares, sortBy, recommendedRule, getRuleParameterEvaluations) => {
    if (fares.length === 0) {
        return []
    }

    const sortedFares = [...fares]
    const getRuleParameterCount = (fare, rule) => {
        const evaluations = getRuleParameterEvaluations(fare, rule)
        return Object.keys(evaluations).reduce((acc, key) => {
            if (evaluations[key]) {
                return acc + 1
            }
            return acc
        }, 0)
    }

    sortedFares.sort((a, b) => {
        switch (sortBy) {
            case SHORTEST: {
                const flightTimeMsA = calculateAverageFareTravelTime(a.airRoutes)
                const flightTimeMsB = calculateAverageFareTravelTime(b.airRoutes)
                const timeDiff = flightTimeMsA - flightTimeMsB
                if (timeDiff === 0) {
                    return defaultSortingRule(a, b)
                }
                return timeDiff
            }
            case RECOMMENDED: {
                const { airlines } = recommendedRule
                if (recommendedRule.isDefault || (airlines.includes(a.platingCarrier) && airlines.includes(b.platingCarrier))) {
                    const validEvaluationsCountA = getRuleParameterCount(a, recommendedRule)
                    const validEvaluationsCountB = getRuleParameterCount(b, recommendedRule)
                    if (validEvaluationsCountA === validEvaluationsCountB) {
                        if (recommendedRule.isDefault || a.platingCarrier === b.platingCarrier) {
                            return defaultSortingRule(a, b)
                        }
                        return airlines.indexOf(a.platingCarrier) - airlines.indexOf(b.platingCarrier)
                    }
                    return validEvaluationsCountB - validEvaluationsCountA
                }
                const ruleIncludesCarrierA = airlines.includes(a.platingCarrier)
                const ruleIncludesCarrierB = airlines.includes(b.platingCarrier)
                // eslint-disable-next-line no-nested-ternary
                return (ruleIncludesCarrierA === ruleIncludesCarrierB) ? 0 : ruleIncludesCarrierA ? -1 : 1
            }
            default:
                return defaultSortingRule(a, b)
        }
    })

    return sortedFares
}

const useSortedFlightResults = (filteredFares, pageContent) => {
    const [sortBy, setSortBy] = useState(CHEAPEST)
    const cheapestFares = useMemo(() => getSortedFares(filteredFares, CHEAPEST), [filteredFares])
    const shortestFares = useMemo(() => getSortedFares(filteredFares, SHORTEST), [filteredFares])

    const {
        getSortingRule,
        getRuleParameterEvaluations,
    } = useSortingRules(pageContent, cheapestFares[0])
    const recommendedRule = getSortingRule(filteredFares)
    const recommendedFares = useMemo(() => getSortedFares(filteredFares, RECOMMENDED, recommendedRule, getRuleParameterEvaluations), [filteredFares])

    const sortedFares = useMemo(() => getSortedFares(filteredFares, sortBy, recommendedRule, getRuleParameterEvaluations), [filteredFares, sortBy])

    return ({
        sortBy,
        sortedFares,
        cheapestFares,
        shortestFares,
        recommendedRule,
        recommendedFares,
        onChangeSortBy: setSortBy,
    })
}

export default useSortedFlightResults
