import { omitEmptyValues } from '@connections/utils'

const Image = ({ filename, alt }) =>
    omitEmptyValues({
        src: filename,
        alt,
        placeholder: 'blur',
    })

export default Image
