import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import RichTextField from '../content/RichTextField'
import Image from './Image'

const CmsLoadingCarousel = ({
    loadingContents,
    loadingItemDelay = 3,
}) => {
    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prev) => {
                if (prev + 1 === loadingContents.length) {
                    return 0
                }
                return prev + 1
            })
        }, loadingItemDelay * 1000)
        return () => clearTimeout(timer)
    }, [])

    const { text, image } = loadingContents[currentIndex]
    return (
        <>
            {image?.filename ? (
                <div className="relative h-64 w-full md:w-1/2 mb-4">
                    <AnimatePresence initial={false}>
                        <motion.div
                            exit="closed"
                            animate="open"
                            initial="closed"
                            key={currentIndex}
                            className="w-full h-full absolute"
                            variants={{
                                open: { opacity: 1 },
                                closed: { opacity: 0 }
                            }}
                        >
                            <Image
                                fill
                                quality={70}
                                {...image}
                                src={image.filename}
                                className="object-cover rounded-lg"
                            />
                        </motion.div>
                    </AnimatePresence>
                </div>
            ) : null}
            {text ? (
                <span className="font-medium text-center">
                    <RichTextField
                        className="last:prose-p:mb-0"
                        content={loadingContents[currentIndex].text}
                    />
                </span>
            ) : null}
        </>
    )
}

export default CmsLoadingCarousel
