import React from 'react'
import SbEditable from 'storyblok-react'
import { Trans } from '@lingui/react'
import clsx from 'clsx'
import Image from '../utils/Image'
import useLocalizedCodes from '../hooks/useLocalizedCodes'
import useFormatFullSlug from '../hooks/useFormatFullSlug'
import { VARIANT_BLUE_OUTLINED } from '../utils/Tag'
import TagList from '../utils/TagList'
import TextLink from '../utils/TextLink'

const ExpertCard = ({
    content,
    className,
}) => {
    const { expert } = content
    if (!expert) {
        return null
    }
    const {
        full_slug: fullSlug,
        content: {
            profileImage,
            firstname,
            lastname,
            travelshop,
            continentCodes,
            regionCodes,
            countryCodes,
            stateCodes,
            iataCodes,
        } = {}
    } = expert
    const formatFullSlug = useFormatFullSlug()
    const tags = useLocalizedCodes({
        continentCodes,
        regionCodes,
        countryCodes,
        stateCodes,
        cityCodes: iataCodes,
    })
    return (
        <SbEditable content={content}>
            <div className={clsx([
                'flex flex-col justify-between',
                className,
            ])}
            >
                <div>
                    <TextLink
                        href={formatFullSlug(fullSlug)}
                        className="w-full text-left flex-wrap"
                        draggable={false}
                    >
                        {profileImage?.filename && (
                            <div className="relative h-[13rem] mb-3 w-full">
                                <Image
                                    fill
                                    quality={80}
                                    maxWidth={750}
                                    draggable={false}
                                    {...profileImage}
                                    src={profileImage.filename}
                                    className="rounded-lg hover:opacity-80 object-cover"
                                />
                            </div>
                        )}
                        <h3 className="font-medium text-xl mb-1">
                            {firstname} {lastname}
                        </h3>
                    </TextLink>
                    {travelshop && travelshop.content && (
                        <h4 className="uppercase block text-xs min-h-[1rem] text-gray-600">
                            {travelshop.content.name}
                        </h4>
                    )}
                    <TagList
                        tags={tags}
                        className="my-5"
                        variant={VARIANT_BLUE_OUTLINED}
                        collapseFrom={4}
                    />
                </div>
                <TextLink
                    href={formatFullSlug(fullSlug, { anchor: 'contact' })}
                    className="border-t w-full py-3 text-blue border-gray-100 hover:underline"
                    iconAfterName="arrow-right"
                    draggable={false}
                >
                    <Trans id="Get in touch with {firstname}" values={{ firstname }} />
                </TextLink>
            </div>
        </SbEditable>
    )
}

export default ExpertCard
