import React from 'react'
import SbEditable from 'storyblok-react'
import Column from '../utils/Column'
import BannerScript from './BannerScript'

const BannerColumn = ({
    content,
}) => {
    const {
        snippet,
    } = content

    return (
        <SbEditable content={content}>
            <Column position="none">
                <BannerScript script={snippet} />
            </Column>
        </SbEditable>
    )
}

export default BannerColumn
