import React from 'react'
import SbEditable from 'storyblok-react'
import useStoryblokBridge from '../../hooks/useStoryblokBridge'
import useDestinationPrice from '../../hooks/useDestinationPrice'
import DestinationPage from './DestinationPage'

const DestinationPageWithState = () => {
    const { content } = useStoryblokBridge()
    const { price } = useDestinationPrice({ content })
    return (
        <SbEditable content={content}>
            <DestinationPage price={price} {...content} />
        </SbEditable>
    )
}

export default DestinationPageWithState
