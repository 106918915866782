import React from 'react'
import clsx from 'clsx'
import * as constants from '@connections/constants'
import { variant } from '../../util/index'
import Image from '../utils/Image'

const {
    IMAGE_STRETCH: STRETCH,
    IMAGE_PORTRAIT: PORTRAIT,
    IMAGE_SQUARE: SQUARE,
    IMAGE_LANDSCAPE: LANDSCAPE,
    COLUMN_LEFT,
    COLUMN_RIGHT,
} = constants

const ContentImage = ({
    image,
    position,
    size = SQUARE,
    maxWidth,
}) => (
    <figure
        className={clsx(
            'relative w-full',
            variant(size, {
                [PORTRAIT]: 'pt-[120%]',
                [SQUARE]: 'pt-[100%]',
                [LANDSCAPE]: 'pt-[50%]',
                [STRETCH]: 'pt-[50%] md:pt-0 md:h-full',
            }),
        )}
    >
        {image !== '' && image.filename !== '' && (
            <div
                className={clsx(
                    'absolute inset-0',
                    variant(position, {
                        [COLUMN_LEFT]: '-right-4 -left-4 lg:right-0 lg:-left-6 xl:left-0',
                        [COLUMN_RIGHT]: '-left-4 -right-4 lg:left-0 lg:-right-6 xl:right-0',
                    })
                )}
            >
                <Image
                    fill
                    quality={70}
                    maxWidth={maxWidth}
                    src={image.filename}
                    className="object-cover"
                    {...image}
                    alt={image.alt || image.filename.split('/').reverse()[0]}
                />
            </div>
        )}
    </figure>
)

export default ContentImage
