import clsx from 'clsx'
import React from 'react'
import SbEditable from 'storyblok-react'
import useCarousel from '@dashdot/react-hooks-slider'
import * as constants from '@connections/constants'
import { variant } from '../../util/index'
import Image from '../utils/Image'
import Column from '../utils/Column'
import SliderContainer from '../utils/CarouselContainer'
import Slide from './Slide'
import CarouselControls from './CarouselControls'

const {
    COLUMN_LEFT,
    COLUMN_RIGHT,
} = constants

const ImageGroupColumn = ({
    content,
    position,
    className,
    background,
}) => {
    const {
        slideRefs,
        isSwiping,
        isDisabled,
        hasNextSlide,
        containerRef,
        swipingProps,
        handleNextSlide,
        hasPreviousSlide,
        handlePreviousSlide,
        position: carouselPosition,
    } = useCarousel()
    const {
        isStacked,
        isCarousel,
        images = [],
        isHorizontal,
    } = content
    const isVertical = !isHorizontal
    return (
        <SbEditable content={content}>
            <Column
                align="center"
                className={clsx(
                    className,
                    isCarousel ? 'relative self-stretch h-[228px] md:h-auto overflow-hidden mt-12 md:mt-0' : ''
                )}
            >
                {(() => {
                    if (isCarousel) {
                        return (
                            <>
                                <SliderContainer
                                    isDisabled={isDisabled}
                                    className="w-full h-full"
                                    position={carouselPosition}
                                    containerRef={containerRef}
                                    swipingProps={swipingProps}
                                >
                                    {images.map((image, index) => (
                                        <Slide
                                            key={image.filename}
                                            isSwiping={isSwiping}
                                            className="relative w-full h-full"
                                            ref={(ref) => { slideRefs.current[index] = ref }}
                                        >
                                            <Image
                                                fill
                                                quality={70}
                                                maxWidth={1080}
                                                {...image}
                                                draggable={false}
                                                src={image.filename}
                                                className="object-cover"
                                            />
                                        </Slide>
                                    ))}
                                </SliderContainer>
                                <CarouselControls
                                    isDisabled={isDisabled}
                                    hasNextSlide={hasNextSlide}
                                    onNextSlide={handleNextSlide}
                                    hasPreviousSlide={hasPreviousSlide}
                                    onPreviousSlide={handlePreviousSlide}
                                />
                            </>
                        )
                    }
                    if (isStacked) {
                        return (
                            <div className="relative md:pr-24 w-full md:w-auto">
                                <div className="bg-gray-100 w-4/5 pt-[150%] md:pt-0 md:w-[26rem] md:h-[36.5rem] transform -rotate-1 relative">
                                    {images.length > 0 && (
                                        <Image
                                            fill
                                            maxWidth={640}
                                            {...images[0]}
                                            src={images[0].filename}
                                            className="object-cover"
                                        />
                                    )}
                                </div>
                                <div className={clsx(
                                    'w-3/4 pt-[120%] md:pt-0 md:w-[20rem] md:h-[28rem] transform rotate-1 absolute top-1/2 -translate-y-1/2 right-0',
                                    variant(background, {
                                        'gray-100': 'bg-white'
                                    }, 'bg-gray-100')
                                )}
                                >
                                    {images.length > 1 && (
                                        <Image
                                            fill
                                            maxWidth={640}
                                            {...images[1]}
                                            className="object-cover"
                                            src={images[1].filename}
                                        />
                                    )}
                                </div>
                            </div>
                        )
                    }
                    return (
                        <div
                            className={clsx(
                                'w-full flex',
                                isVertical && 'space-x-4 md:space-x-6',
                                isHorizontal && 'flex-col h-[40rem] space-y-4 md:space-y-6'
                            )}
                        >
                            <div
                                className={clsx(
                                    'flex-1 relative bg-gray-100 ',
                                    isVertical && 'md:w-1/2 md:h-[32rem]',
                                )}
                            >
                                {images.length > 0 && (
                                    <div
                                        className={clsx(
                                            'absolute inset-0',
                                            isVertical && variant(position, {
                                                [COLUMN_LEFT]: '-left-4 lg:-left-6 xl:left-0',
                                                [COLUMN_RIGHT]: '-left-4 lg:left-0',
                                            }),
                                            isHorizontal && variant(position, {
                                                [COLUMN_LEFT]: '-left-4 -right-4 lg:right-0 lg:-left-6 xl:left-0',
                                                [COLUMN_RIGHT]: '-left-4 -right-4 lg:left-0 lg:-right-6 xl:right-0',
                                            })
                                        )}
                                    >
                                        <Image
                                            fill
                                            maxWidth={1080}
                                            {...images[0]}
                                            src={images[0].filename}
                                            className="object-cover"
                                        />
                                    </div>
                                )}
                            </div>
                            <div
                                className={clsx(
                                    'flex-1 relative bg-gray-100 ',
                                    isVertical && 'md:w-1/2 md:h-[32rem]',
                                )}
                            >
                                {images.length > 1 && (
                                    <div
                                        className={clsx(
                                            'absolute inset-0',
                                            isVertical && variant(position, {
                                                [COLUMN_LEFT]: '-right-4 lg:right-0',
                                                [COLUMN_RIGHT]: '-right-4 lg:-right-6 xl:right-0',
                                            }),
                                            isHorizontal && variant(position, {
                                                [COLUMN_LEFT]: '-left-4 -right-4 lg:right-0 lg:-left-6 xl:left-0',
                                                [COLUMN_RIGHT]: '-left-4 -right-4 lg:left-0 lg:-right-6 xl:right-0',
                                            })
                                        )}
                                    >
                                        <Image
                                            fill
                                            maxWidth={1080}
                                            {...images[1]}
                                            className="object-cover"
                                            src={images[1].filename}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                })()}
            </Column>
        </SbEditable>
    )
}

export default ImageGroupColumn
