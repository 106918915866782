import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import * as constants from '@connections/constants'
import { variant } from '../../util/index'
import * as styles from '../../util/styles'
import { sectionBackgroundVariant, textColorVariant } from '../../util/variants'
import Price from '../utils/Price'
import Video from '../utils/Video'
import CustomText from '../utils/CustomText'
import Image, { createLoader } from '../utils/Image'
import Button, { VARIANT_BLUE_FILLED } from '../utils/Button'
import DynamicButton from './DynamicButton'

const { SIZE_FULL: FULL } = constants

const { SCREEN_SIZE_SM: SM } = styles

const HeadingMobile = ({
    size,
    href,
    image,
    title,
    price,
    hasArc,
    gradient,
    hasNoQsm,
    subTitle,
    className,
    titleColor,
    background,
    video = {},
    description,
    buttons = [],
    contentImage,
    centerMobile,
    subTitleColor,
    descriptionColor,
    fullHeightMobile,
    isSlideShow = false,
    contentImageMaxWidth,
    contentImageMaxHeight,
    pricePrefix = <Trans id="from" />,
    gradientMobile: gradientMobileFromProps,
}) => {
    const hasVideo = video?.filename
    const hasImage = image?.filename
    const isSlideShowWithQsm = isSlideShow && !hasNoQsm
    const isSlideShowWithoutQsm = isSlideShow && hasNoQsm
    const gradientMobile = gradientMobileFromProps || gradient // backwards compatibility
    const contentImageLoader = createLoader({ maxWidth: 600 })

    return (
        <div
            className={clsx(
                'bg-black flex-1 w-screen',
                hasArc && 'has-bottom-arc',
                isSlideShowWithoutQsm && 'pb-56',
                isSlideShowWithQsm && !fullHeightMobile && 'pb-72 -mb-56',
                className
            )}
        >
            <div
                className={clsx(
                    !fullHeightMobile && 'relative h-[30vh]',
                    !isSlideShow && !fullHeightMobile && '-mt-24'
                )}
            >
                <div className="absolute h-full w-full z-1 -top-px">
                    {hasVideo && (
                        <Video
                            loop
                            muted
                            autoPlay
                            playsInline
                            src={video.filename}
                            screenSizesToRender={[SM]}
                            className="absolute h-full w-full inset-0 object-cover"
                        />
                    )}
                    {!hasVideo && hasImage && (
                        <Image
                            fill
                            priority
                            sizes="(max-width: 768px) 768px, (max-width: 1200px) 1200px, (max-width: 1600px) 1600px, 100vw"
                            {...image}
                            src={image.filename}
                            className="object-cover"
                        />
                    )}
                </div>
                {background && background !== '' && (
                    <div
                        className={clsx(
                            'absolute z-1 inset-0 opacity-80',
                            sectionBackgroundVariant(background)
                        )}
                    />
                )}
                {/* gradient should not be dependable on fullHeightMobile but is kept for backwards compatibility */}
                {gradientMobile !== 'none' || !fullHeightMobile ? (
                    <div
                        className={clsx(
                            'absolute z-1 heading-gradient',
                            variant(gradientMobile, {
                                small: 'heading-gradient-sm h-[14rem] bottom-0 left-0 w-full',
                                default: 'heading-gradient inset-0',
                                large: 'heading-gradient-lg inset-0',
                                extraLarge: 'heading-gradient-xl inset-0',
                            })
                        )}
                    />
                ) : null}
            </div>
            <div
                className={clsx(
                    centerMobile && 'items-center text-center',
                    !fullHeightMobile && !isSlideShow && '-mt-24',
                    fullHeightMobile && 'h-full justify-center mb-0',
                    'container w-full mb-10 z-1 flex flex-col relative',
                    variant(size, {
                        [FULL]: 'h-screen',
                    })
                )}
            >
                {contentImage?.src && (
                    <div
                        className="relative"
                        style={{
                            maxWidth:
                                contentImageMaxWidth &&
                                `${contentImageMaxWidth}px`,
                            maxHeight:
                                contentImageMaxHeight &&
                                `${contentImageMaxHeight}px`,
                        }}
                    >
                        <img
                            className="mb-2"
                            {...contentImage}
                            src={contentImageLoader({
                                src: contentImage.src,
                                width: contentImageMaxWidth || 300,
                            })}
                        />
                    </div>
                )}
                {title && (
                    <CustomText
                        as="h1"
                        className={clsx(
                            'bottom-0 text-4xl font-medium mb-3',
                            textColorVariant(titleColor)
                        )}
                        customText={title}
                        customTextClassName="font-accent text-5xl break-words"
                    />
                )}
                {price !== null && price !== '' && !subTitle && (
                    <h3 className="text-white text-lg font-medium mb-2">
                        <Price price={price} prefix={pricePrefix} />
                    </h3>
                )}
                {subTitle && (
                    <h3
                        className={clsx(
                            'text-lg font-medium mb-4',
                            textColorVariant(subTitleColor)
                        )}
                    >
                        {subTitle}
                    </h3>
                )}
                {description && (
                    <p
                        className={clsx(
                            textColorVariant(descriptionColor),
                            hasArc && hasNoQsm ? 'mb-7' : 'mb-4',
                            'text-white leading-normal whitespace-pre-wrap'
                        )}
                    >
                        {description}
                    </p>
                )}
                {buttons.length > 0 && (
                    <div className="flex flex-wrap gap-4">
                        {buttons.map((button) => (
                            <DynamicButton content={button} key={button._uid} />
                        ))}
                    </div>
                )}
                {buttons.length === 0 && href && (
                    <Button
                        as="a"
                        href={href}
                        iconAfterName="arrow-right"
                        variant={VARIANT_BLUE_FILLED}
                    >
                        <Trans id="Discover" />
                    </Button>
                )}
            </div>
        </div>
    )
}

export default HeadingMobile
