/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect } from 'react'
import { getScreenSize } from '../../util/styles'
import useResize from '../hooks/useResize'

const VIDEO_FIXED_PATH = '/video/upload'

const loader = ({
    src,
    blur = null,
    width = null,
    quality = 'auto:eco',
    endOffset = null,
    extension = 'mp4',
    startOffset = null,
}) => {
    const fixedPath = process.env.NEXT_PUBLIC_CLOUDINARY_FIXED_PATH
    const oldFixedPath = process.env.NEXT_PUBLIC_CLOUDINARY_OLD_FIXED_PATH

    const modifiers = [
        `q_${quality}`,
        width !== null ? `w_${width}` : null,
        blur !== null ? `e_blur:${blur}` : null,
    ]
    const isOld = src.includes(oldFixedPath)
    const path = `${fixedPath}${VIDEO_FIXED_PATH}`
    let pathToSplit = `${fixedPath}${VIDEO_FIXED_PATH}`
    if (isOld) {
        pathToSplit = `${oldFixedPath}${VIDEO_FIXED_PATH}`
    }
    const [, dynamicPart] = src.split(pathToSplit)
    const dynamicPartArr = dynamicPart.split('.')
    dynamicPartArr.pop()
    const publicId = dynamicPartArr.join('.')
    const startOffsetString =
        startOffset !== null && startOffset !== ''
            ? `so_${startOffset}/`
            : 'so_0/'
    const endOffsetString =
        endOffset !== null && endOffset !== '' ? `eo_${endOffset}/` : ''
    return `${path}/${startOffsetString}${endOffsetString}${modifiers
        .filter((i) => i)
        .join(',')}/${publicId}.${extension}`
}

const Video = ({
    src,
    quality,
    autoPlay,
    endOffset,
    startOffset,
    maxWidth = 1920,
    screenSizesToRender,
    ...props
}) => {
    const ref = useRef()

    const automaticallyPlayVideo = () => {
        if (ref.current && ref.current.paused) {
            const screenSize = getScreenSize()
            // Autoplay causes the video to load completely even though it is hidden through css.
            // That's why we only set autoPlay when the renderdeByDevice matches the screenSize.
            const canPlay =
                !screenSizesToRender || screenSizesToRender.includes(screenSize)
            if (canPlay && autoPlay) {
                try {
                    ref.current.play()
                } catch (error) {
                    //Swallow video autoplay error
                    // If this is really an issue, we can show controls on error etc.
                    // But in a lot of cases this is triggered when the user has disabled autoplay in their browser.
                    // https://developer.chrome.com/blog/autoplay/
                }
            }
        }
    }

    useResize(() => {
        automaticallyPlayVideo()
    })

    useEffect(() => {
        automaticallyPlayVideo()
    }, [ref.current])

    return (
        <video
            ref={ref}
            preload="metadata"
            poster={loader({
                src,
                quality,
                startOffset,
                extension: 'jpg',
                width: maxWidth / 2,
            })}
            {...props}
        >
            <source
                src={loader({
                    src,
                    quality,
                    endOffset,
                    startOffset,
                    width: maxWidth,
                })}
            />
        </video>
    )
}

export default Video
