import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import { variant } from '../../util/index'
import Image from '../utils/Image'
import Video from '../utils/Video'
import CustomText from '../utils/CustomText'
import RichTextField from './RichTextField'
import DynamicButton from './DynamicButton'

const Card = ({
    content,
    className,
}) => {
    const {
        text,
        image,
        title,
        buttons,
        alignContent,
    } = content
    const video = content.video?.[0]
    const hasImage = !!image?.filename

    return (
        <SbEditable content={content}>
            <div
                className={
                    clsx(
                        'relative bg-white rounded-lg shadow-card overflow-hidden h-full',
                        className,
                    )
                }
            >
                {hasImage && (
                    <div className="w-full h-[21rem] relative">
                        <Image
                            fill
                            quality={80}
                            maxWidth={750}
                            {...image}
                            src={image.filename}
                            className="object-cover"
                        />
                    </div>
                )}
                {!hasImage && video && (
                    <div className="w-full h-[21rem] relative">
                        <Video
                            playsInline
                            loop={video.loop}
                            muted={video.muted}
                            autoPlay={video.autoPlay}
                            src={video.video.filename}
                            controls={video.showControls}
                            className="absolute h-full w-full inset-0 object-cover"
                        />
                    </div>
                )}
                <div className="p-8">
                    <div
                        className={clsx(
                            variant(alignContent, {
                                left: 'text-left',
                                center: 'text-center',
                                right: 'text-right',
                            }, 'text-left')
                        )}
                    >
                        {title && (
                            <CustomText
                                as="h3"
                                className="text-2xl font-medium mb-4 text-black"
                                customText={title}
                            />
                        )}
                        {text && (
                            <RichTextField
                                content={text}
                                className=" text-black"
                            />
                        )}
                    </div>
                    {buttons.length > 0 && (
                        <div className="flex flex-wrap gap-4">
                            {buttons.map((button) => (
                                <DynamicButton
                                    key={button._uid}
                                    content={button}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </SbEditable>
    )
}

export default Card
