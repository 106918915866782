import React from 'react'
import SbEditable from 'storyblok-react'
import useStoryblokBridge from '../../hooks/useStoryblokBridge'
import HotelPage from './HotelPage'

const HotelPageWithState = () => {
    const { content } = useStoryblokBridge()

    return (
        <SbEditable content={content}>
            <HotelPage {...content} />
        </SbEditable>
    )
}

export default HotelPageWithState
