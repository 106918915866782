import React from 'react'
import SbEditable from 'storyblok-react'
import useStoryblokBridge from '../../hooks/useStoryblokBridge'
import ActivityPage from './ActivityPage'

const ActivityPageWithState = () => {
    const { content } = useStoryblokBridge()
    return (
        <SbEditable content={content}>
            <ActivityPage {...content} />
        </SbEditable>
    )
}

export default ActivityPageWithState
