import React from 'react'
import SbEditable from 'storyblok-react'
import useStoryblokBridge from '../../hooks/useStoryblokBridge'
import JobPage from './JobPage'

const JobPageWithState = () => {
    const { content } = useStoryblokBridge()

    return (
        <SbEditable content={content}>
            <JobPage {...content} />
        </SbEditable>
    )
}

export default JobPageWithState
