import React from 'react'
import { Trans } from '@lingui/react'
import SbEditable from 'storyblok-react'
import NextLink from 'next/link'
import Image from '../utils/Image'
import Button, { VARIANT_OUTLINED } from '../utils/Button'
import CustomText from '../utils/CustomText'
import { getEntityContentOverrides } from '../../util/entities'
import usePathnameLocale from '../hooks/usePathnameLocale'

const HeaderDesktopMenuItemDropdownCard = ({
    content,
    onClick,
    ...props
}) => {
    const {
        tour,
        page,
        breakItem,
        activity,
        destination,
        hideGradient,
    } = content
    const linkedItem = tour || breakItem || activity || destination || page
    // linked item might be null or might be an ID (string) in either case we do not render the card
    if (!linkedItem || typeof linkedItem === 'string') {
        return null
    }
    const locale = usePathnameLocale()
    const {
        href,
        image,
        title,
        description,
    } = getEntityContentOverrides(content, linkedItem, locale)
    return (
        <SbEditable content={content}>
            <div className="relative w-full h-full flex flex-col justify-end" {...props}>
                <Image
                    fill
                    quality={80}
                    maxWidth={750}
                    {...image}
                    src={image.filename}
                    className="object-cover"
                />
                {!hideGradient && (
                    <div className="absolute bottom-0 inset-x-0 h-3/4 card-gradient" />
                )}
                <div className="z-10 p-6">
                    {title && (
                        <CustomText
                            as="h3"
                            className="text-white text-2xl mb-2 font-medium"
                            customText={title}
                        />
                    )}
                    {description && (
                        <p className="text-white text-sm mb-6 max-w-xs">
                            {description || linkedItem.content.description}
                        </p>
                    )}
                    <NextLink legacyBehavior href={href} passHref>
                        <Button
                            as="a"
                            variant={VARIANT_OUTLINED}
                            iconAfterName="arrow-right"
                        >
                            <Trans id="Discover" />
                        </Button>
                    </NextLink>
                </div>
            </div>
        </SbEditable>
    )
}

export default HeaderDesktopMenuItemDropdownCard
