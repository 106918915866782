import React from 'react'
import SbEditable from 'storyblok-react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import Image from '../utils/Image'
import Icon from '../utils/Icon'
import useFormatFullSlug from '../hooks/useFormatFullSlug'
import { joinWithoutEmpty } from '../../util/index'
import TextLink from '../utils/TextLink'
import JsonLd from '../utils/JsonLd'

const Row = ({
    icon,
    children,
}) => (
    <div className="flex text-sm text-gray-600">
        <Icon
            name={icon}
            size="smaller"
            className="mt-0.5 mr-2 text-blue shrink-0"
        />
        {children}
    </div>
)

const ShopCard = ({
    content,
    className,
}) => {
    const formatFullSlug = useFormatFullSlug()
    const { shop } = content
    if (!shop) {
        return null
    }

    const {
        full_slug,
        content: {
            title,
            headImage,
            email,
            phone,
            street,
            streetNumber,
            postalCode,
            city,
        } = {}
    } = shop

    if (!full_slug) {
        return null
    }
    const addressLevel1 = joinWithoutEmpty([street, streetNumber], ' ')
    const addressLevel2 = joinWithoutEmpty([postalCode, city], ' ')
    const address = joinWithoutEmpty([addressLevel1, addressLevel2], ', ')

    return (
        <SbEditable content={content}>
            <div
                className={clsx([
                    'flex flex-col justify-between',
                    className,
                ])}
            >
                <JsonLd
                    type="LocalBusiness"
                    item={{
                        name: title,
                        telephone: phone,
                        email,
                        address: {
                            '@type': 'PostalAddress',
                            addressLocality: city,
                            postalCode,
                            streetAddress: `${street} ${streetNumber}`,
                        },
                    }}
                />
                <TextLink
                    href={formatFullSlug(full_slug)}
                    className="w-full text-left flex-wrap"
                    draggable={false}
                >
                    {!!headImage?.filename && (
                        <div className="relative h-[13rem] mb-3 w-full">
                            <Image
                                fill
                                quality={80}
                                maxWidth={750}
                                draggable={false}
                                {...headImage}
                                src={headImage.filename}
                                className="object-cover"
                            />
                        </div>
                    )}
                    <h3 className="font-medium text-xl mb-2 block">
                        {title}
                    </h3>
                </TextLink>

                <div className="flex-1 flex flex-col justify-between">
                    <div className="mb-6 space-y-2">
                        <Row icon="location-point">
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://maps.google.com/?q=${address}`}
                            >
                                {address}
                            </a>
                        </Row>
                        <Row icon="phone">
                            <a href={`tel:${phone}`}>
                                {phone}
                            </a>
                        </Row>
                        <Row icon="mailbox">
                            <a href={`mailto:${email}`}>
                                {email}
                            </a>
                        </Row>
                    </div>
                    <TextLink
                        href={formatFullSlug(full_slug)}
                        className="text-blue-bright flex items-center pt-3 border-t border-gray-100"
                        iconAfterName="arrow-right"
                        draggable={false}
                    >
                        <Trans id="More information" />
                    </TextLink>
                </div>
            </div>
        </SbEditable>
    )
}

export default ShopCard
