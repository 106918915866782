'use client'

import React from 'react'
import { notFound } from 'next/navigation'
import PageUnknown from './layout/PageUnknown'
import ZendeskScript from './utils/ZendeskScript'
import Job from './pages/job/JobPageWithState'
import Shop from './pages/shop/ShopPageWithState'
import Tour from './pages/tour/TourPageWithState'
import Break from './pages/break/BreakPageWithState'
import Hotel from './pages/hotel/HotelPageWithState'
import Expert from './pages/expert/ExpertPageWithState'
import SearchPage from './pages/search/SearchPageWithState'
import Activity from './pages/activity/ActivityPageWithState'
import BlogPost from './pages/blog-post/BlogPostPageWithState'
import Excursion from './pages/excursion/ExcursionPageWithState'
import Experience from './pages/experience/ExperiencePageWithState'
import Destination from './pages/destination/DestinationPageWithState'
import ShopSearchPage from './pages/shop-search/ShopSearchPageWithState'
import DefaultPageWithState from './pages/default-page/DefaultPageWithState'
import FlightSearchPage from './pages/flight-search/FlightSearchPageWithState'
import TravelSearchPage from './pages/travel-search/TravelSearchPageWithState'
import ExpertSearchPage from './pages/expert-search/ExpertSearchPageWithState'
import BlogPostSearchPage from './pages/blog-post-search/BlogPostSearchPageWithState'
import ExperiencesSearchPage from './pages/experiences-search/ExperiencesSearchPageWithState'
import usePageProps from './hooks/usePageProps'

const Pages = {
    Job,
    Shop,
    Tour,
    Break,
    Hotel,
    Expert,
    Activity,
    BlogPost,
    Excursion,
    SearchPage,
    Experience,
    Destination,
    ShopSearchPage,
    TravelSearchPage,
    FlightSearchPage,
    ExpertSearchPage,
    BlogPostSearchPage,
    ExperiencesSearchPage,
    Page: DefaultPageWithState,
    Airline: DefaultPageWithState,
}

const AppRouter = () => {
    const {
        data: { pageStory },
    } = usePageProps()

    if (pageStory === null) {
        return notFound()
    }

    const { component, loadZendesk } = pageStory.content
    const Page = Pages[component] || null

    if (Page === null) {
        return <PageUnknown />
    }

    return (
        <>
            <Page />
            <ZendeskScript loadZendesk={loadZendesk} />
        </>
    )
}

export default AppRouter
