import React from 'react'
import { Trans } from '@lingui/react'
import SbEditable from 'storyblok-react'
import { ALIGN_CENTER } from '@connections/constants'
import { joinWithoutEmpty } from '../../../util/index'
import Page from '../../layout/Page'
import PageMain from '../../layout/PageMain'
import StickyHeader from '../../layout/StickyHeader'
import HeaderWithContent from '../../layout/HeaderWithContent'
import FooterWithContent from '../../layout/FooterWithContent'
import Heading from '../../content/Heading'
import ContentColumn from '../../content/ContentColumn'
import DynamicSection from '../../content/DynamicSection'
import JsonLd from '../../utils/JsonLd'
import LocaleLink from '../../utils/LocaleLink'
import BulletItemWithState from '../../utils/BulletItemWithState'

const ShopPage = ({
    body,
    city,
    phone,
    title,
    email,
    street,
    headImage,
    postalCode,
    description,
    streetNumber,
    openingHours,
}) => {
    const addressLevel1 = joinWithoutEmpty([street, streetNumber], ' ')
    const addressLevel2 = joinWithoutEmpty([postalCode, city], ' ')
    const address = joinWithoutEmpty([addressLevel1, addressLevel2], ', ')

    return (
        <Page data-testid="ShopPage">
            <JsonLd
                type="LocalBusiness"
                item={{
                    name: title,
                    telephone: phone,
                    email,
                    address: {
                        '@type': 'PostalAddress',
                        addressLocality: city,
                        postalCode,
                        streetAddress: `${street} ${streetNumber}`,
                    },
                }}
            />
            <StickyHeader>
                <HeaderWithContent />
            </StickyHeader>
            <PageMain>
                <Heading
                    title={title}
                    image={headImage}
                    size="small"
                    position={ALIGN_CENTER}
                    gradient="large"
                />
                <section className="container py-16 md:pt-16 md:pb-32">
                    <LocaleLink
                        href="/reiswinkels"
                        iconBeforeName="arrow-left"
                        className="text-blue mb-6 md:mb-11"
                    >
                        <Trans id="Back to overview" />
                    </LocaleLink>
                    <div className="md:flex md:space-x-8 items-start">
                        {description && description.length > 0 && (
                            <div className="mb-16 md:mb-0 mr-auto flex-1">
                                <SbEditable content={description[0]}>
                                    <ContentColumn content={description[0]} />
                                </SbEditable>
                            </div>
                        )}
                        <div className="space-y-4 flex-1">
                            <BulletItemWithState
                                icon="location-point"
                                label={<Trans id="Location" />}
                            >
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`https://maps.google.com/?q=${address}`}
                                >
                                    {address}
                                </a>
                            </BulletItemWithState>
                            <BulletItemWithState
                                icon="phone"
                                label={<Trans id="Phone" />}
                            >
                                <a href={`tel:${phone}`}>
                                    {phone}
                                </a>
                            </BulletItemWithState>
                            <BulletItemWithState
                                icon="mailbox"
                                label={<Trans id="Mail" />}
                            >
                                <a href={`mailto:${email}`}>
                                    {email}
                                </a>
                            </BulletItemWithState>
                            <BulletItemWithState
                                icon="shop"
                                label={<Trans id="Opening hours" />}
                            >
                                {openingHours}
                            </BulletItemWithState>
                        </div>
                    </div>
                </section>
                {body && body.map((content) => (
                    <DynamicSection
                        key={content._uid}
                        content={content}
                    />
                ))}
            </PageMain>
            <FooterWithContent />
        </Page>
    )
}

export default ShopPage
