/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Link from 'next/link'
import { Trans } from '@lingui/react'
import clsx from 'clsx'
import useFormatFullSlug from '../hooks/useFormatFullSlug'
import useLocalizedAirline from '../hooks/useLocalizedAirlineDeprecated'
import Date from '../utils/Date'
import Price from '../utils/Price'
import Image from '../utils/Image'
import JsonLd from '../utils/JsonLd'
import Tag, { VARIANT_BLUE_BORDERLESS } from '../utils/Tag'

const PriceTag = ({ price, hasPrice, className }) => (
    <Tag variant={VARIANT_BLUE_BORDERLESS} className={clsx('!lowercase mb-1.5 !text-base !lg:text-sm', className)}>
        {hasPrice ? (
            <Price price={price} />
        ) : (
            <span className="capitalize">
                <Trans id="No pricing found" />
            </span>
        )}
    </Tag>
)

const FlightsListerDestinationCard = ({
    destination,
}) => {
    const {
        content,
        flightPrice = {},
        full_slug: fullSlug,
    } = destination
    const {
        name,
        headImage,
    } = content
    const {
        price,
        returnDate,
        airlineCode,
        departureDate,
    } = flightPrice
    const formatFullSlug = useFormatFullSlug()
    const airline = useLocalizedAirline(airlineCode)
    const hasImage = headImage?.filename
    const hasPrice = typeof price !== 'undefined'
    const isRoundTrip = departureDate && returnDate

    return (
        <Link
            href={formatFullSlug(fullSlug)}
            className="relative rounded-lg shadow-md group"
        >
            <JsonLd
                type="Flight"
                item={{
                    provider: airline.name,
                    arrivalTime: returnDate,
                    departureTime: departureDate,
                }}
            />
            <div className="relative w-full h-40 rounded-t-md overflow-hidden bg-gray-50">
                <PriceTag
                    price={price}
                    hasPrice={hasPrice}
                    className="absolute top-2 left-1.5 z-10 lg:hidden"
                />
                {hasImage && (
                    <Image
                        fill
                        maxWidth={400}
                        src={headImage.filename}
                        className="absolute inset-0 object-cover h-full w-full"
                        alt={headImage.alt || headImage.filename.split('/').reverse()[0]}
                        {...headImage}
                    />
                )}
            </div>
            <div className="lg:flex justify-between px-4 py-3 border border-gray-100 rounded-b-md bg-white">
                <div className="flex flex-1 flex-col items-start">
                    <span className="text-lg text-blue font-bold mb-1.5 group-hover:underline underline-offset-4 decoration-2">{name}</span>
                    <span className="text-sm text-gray-600">{airline.name}</span>
                </div>
                <div className="flex-1 lg:flex flex-col items-end">
                    <PriceTag
                        price={price}
                        hasPrice={hasPrice}
                        className="hidden lg:block"
                    />
                    <span className="text-sm text-gray-600 truncate">
                        {(() => {
                            if (hasPrice) {
                                if (isRoundTrip) {
                                    return (
                                        <>
                                            <Date dateString={departureDate} format="dd MMM" />
                                            {' - '}
                                            <Date dateString={returnDate} format="dd MMM" />
                                        </>
                                    )
                                }
                                return <Date dateString={departureDate} format="dd MMM" />
                            }
                            return <Trans id="No date found" />
                        })()}
                    </span>
                </div>
            </div>
        </Link>
    )
}

export default FlightsListerDestinationCard
