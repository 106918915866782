import React from 'react'
import SbEditable from 'storyblok-react'
import useStoryblokBridge from '../../hooks/useStoryblokBridge'
import ExcursionPage from './ExcursionPage'

const ExcursionPageWithState = () => {
    const { content } = useStoryblokBridge()
    return (
        <SbEditable content={content}>
            <ExcursionPage content={content} />
        </SbEditable>
    )
}

export default ExcursionPageWithState
