import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import useStoryblokBridge from '../../hooks/useStoryblokBridge'
import { useShopsSearchQueryParams } from '../../content/useQuickSearchShopsFormState'
import ShopSearchPage from './ShopSearchPage'
import useShopSearchPageQuery from './useShopSearchPageQuery'
import QueryParamProvider from '../../providers/QueryParamProvider'

const ShopSearchPageWithState = () => {
    const { content } = useStoryblokBridge()
    const [query] = useShopsSearchQueryParams()
    const [loadMoreOnPageEnd, setLoadMoreOnPageEnd] = useState(false)
    const {
        hasMore,
        isFetching,
        searchResults,
        isFetchingMore,
        handleFetchMore,
    } = useShopSearchPageQuery(query)

    return (
        <SbEditable content={content}>
            <ShopSearchPage
                content={content}
                hasMore={hasMore}
                isFetching={isFetching}
                onFetchMore={handleFetchMore}
                searchResults={searchResults}
                isFetchingMore={isFetchingMore}
                loadMoreOnPageEnd={loadMoreOnPageEnd}
                onLoadMore={() => setLoadMoreOnPageEnd(true)}
            />
        </SbEditable>
    )
}

export default (props) => (
    <QueryParamProvider>
        <ShopSearchPageWithState {...props} />
    </QueryParamProvider>
)
