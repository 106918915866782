import clsx from 'clsx'
import React from 'react'
import { format } from 'date-fns'
import { SbToValidDate } from '@connections/utils'
import { motion, AnimatePresence } from 'framer-motion'
import { sectionBackgroundVariant, sectionContainerVariant, sectionSpacingVariant } from '../../util/variants'
import Icon from '../utils/Icon'
import Image from '../utils/Image'
import Select from '../utils/Select'
import SbButton from '../utils/SbButton'
import SbLanguage from '../utils/SbLanguage'
import CustomText from '../utils/CustomText'
import Button, { SIZE_SMALLER, VARIANT_BLUE_OUTLINED } from '../utils/Button'

const ToggleDescription = ({
    uid,
    isActive,
    className,
    onHideSlot,
    onShowSlot,
    expandText,
    collapseText,
}) => (
    <Button
        size={SIZE_SMALLER}
        variant={VARIANT_BLUE_OUTLINED}
        onClick={() => (isActive
            ? onHideSlot()
            : onShowSlot(uid)
        )}
        className={clsx('w-36 flex items-center', className)}
    >
        <span className="text-center flex-1">
            {isActive ? expandText : collapseText}
        </span>
        <Icon name={isActive ? 'chevron-up' : 'chevron-down'} />
    </Button>
)

const ProgramSection = ({
    title,
    groups,
    spacing,
    buttons,
    anchorId,
    container,
    onShowSlot,
    onHideSlot,
    expandText,
    background,
    description,
    groupsTitle,
    collapseText,
    selectedSlot,
    selectedGroup,
    onGroupChange,
    selectedGroupUuid,
}) => (
    <section
        className="pb-64"
        id={anchorId || undefined}
        data-testid="ProgramSection"
    >
        <div
            className={clsx(
                sectionSpacingVariant({ spacing }),
                sectionBackgroundVariant(background),
            )}
        >
            <div className={clsx('-mb-64', sectionContainerVariant(container))}>
                <div className="text-center mb-8 md:mb-16">
                    <CustomText
                        as="h3"
                        customText={title}
                        className="text-4xl font-medium mb-4"
                        customTextClassName="font-accent text-5xl"
                    />
                    <p className="">
                        {description}
                    </p>
                </div>

                <div className="bg-white rounded-lg shadow-md">
                    <nav className="border-b border-gray-100 flex items-center justify-between px-4 md:px-14 py-2 md:py-8">
                        <div className="border-l-4 border-blue font-medium text-lg md:text-2xl pl-3 md:pl-5 mr-4">
                            {groupsTitle}
                        </div>
                        <Select
                            value={selectedGroupUuid}
                            className="md:hidden w-auto text-center"
                            onChange={(e) => onGroupChange(e.target.value)}
                        >
                            {groups.map((group) => (
                                <option key={group.uuid} value={group.uuid}>
                                    {group.title}
                                </option>
                            ))}
                        </Select>
                        <div className="hidden md:flex gap-4">
                            {groups.map((group) => (
                                <button
                                    type="button"
                                    key={group.uuid}
                                    onClick={() => onGroupChange(group.uuid)}
                                    className={clsx(
                                        'text-gray-600 px-4 py-2',
                                        selectedGroupUuid === group.uuid && 'bg-blue bg-opacity-10 rounded-lg text-blue'
                                    )}
                                >
                                    {group.title}
                                </button>
                            ))}
                        </div>
                    </nav>
                    <div className="px-4 md:py-2 md:px-14 lg:px-28">
                        {selectedGroup?.slots?.map((slot) => {
                            const {
                                _uid,
                                timeEnd,
                                timeStart,
                                language,
                                programBlock: { image }
                            } = slot
                            const isActive = selectedSlot === _uid
                            const end = timeEnd && format(SbToValidDate(timeEnd), 'HH:mm')
                            const start = timeStart && format(SbToValidDate(timeStart), 'HH:mm')

                            return (
                                <div className="py-6 flex flex-wrap md:flex-nowrap w-full border-b border-gray-100 last:border-0" key={_uid}>
                                    <div className={clsx(
                                        'w-48 h-48 relative rounded-full overflow-hidden will-change-transform flex-shrink-0 mx-auto mb-6',
                                        'md:mr-8 md:ml-0 md:mb-0 md:w-24 md:h-24',
                                        'lg:mr-16',
                                    )}
                                    >
                                        {image?.src && (
                                            <Image
                                                fill
                                                zoom="0.5"
                                                crop="thumb"
                                                gravity="face"
                                                maxWidth={192}
                                                maxHeight={192}
                                                src={image.src}
                                                {...image}
                                                className="object-cover z-1"
                                            />
                                        )}
                                        <div className="absolute inset-0 skeleton" />
                                    </div>
                                    <div className="w-full md:flex-1">
                                        <div className="flex flex-col md:flex-row justify-between items-center mb-8 md:mb-0">
                                            <div className="flex flex-col items-center md:items-start">
                                                <h4 className="mb-1 text-lg font-medium">
                                                    {slot.programBlock.title}
                                                </h4>
                                                <p className="mb-3 text-base text-gray-600">
                                                    {slot.title}
                                                </p>
                                                <div className="flex gap-x-8 gap-y-2 flex-wrap justify-center text-sm">
                                                    <div className="flex gap-2">
                                                        <Icon
                                                            size="small"
                                                            name="clock"
                                                            color="blue-bright"
                                                        />
                                                        {start} - {end}
                                                    </div>
                                                    <div className="flex gap-2">
                                                        <Icon
                                                            size="small"
                                                            name="comment-dots"
                                                            color="blue-bright"
                                                        />
                                                        <SbLanguage language={language} />
                                                    </div>
                                                </div>
                                            </div>
                                            <ToggleDescription
                                                uid={_uid}
                                                isActive={isActive}
                                                onHideSlot={onHideSlot}
                                                onShowSlot={onShowSlot}
                                                expandText={collapseText}
                                                collapseText={expandText}
                                                className="hidden md:flex"
                                            />
                                        </div>
                                        <AnimatePresence>
                                            {isActive && (
                                                <motion.div
                                                    className="overflow-hidden"
                                                    exit={{ opacity: 0, height: 0 }}
                                                    initial={{ opacity: 0, height: 0 }}
                                                    animate={{ opacity: 1, height: 'auto' }}
                                                >
                                                    <p className="mb-8 md:mt-8">
                                                        {slot.description || slot.programBlock.description}
                                                    </p>
                                                </motion.div>
                                            )}
                                        </AnimatePresence>
                                        <div className="flex justify-center md:hidden">
                                            <ToggleDescription
                                                uid={_uid}
                                                isActive={isActive}
                                                onHideSlot={onHideSlot}
                                                onShowSlot={onShowSlot}
                                                expandText={collapseText}
                                                collapseText={expandText}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                {buttons?.length > 0 && (
                    <div className="flex mt-10 md:mt-20 w-full items-center justify-center flex-wrap">
                        {buttons.map((button) => (
                            <SbButton content={button} key={button._uid} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    </section>
)

export default ProgramSection
