import React from 'react'
import NextImage from 'next/image'

const IMAGE_FIXED_PATH = '/image/upload'

export const cloudinaryLoader = ({
    src,
    zoom,
    width,
    height,
    effect,
    quality,
    crop = 'fill',
    gravity = 'auto',
}) => {
    const fixedPath = process.env.NEXT_PUBLIC_CLOUDINARY_FIXED_PATH
    const oldFixedPath = process.env.NEXT_PUBLIC_CLOUDINARY_OLD_FIXED_PATH

    // If quality is not set lower the quality for smaller images
    let finalQuality = quality
    if (!quality) {
        finalQuality = 'auto:best'
        if (width <= 600) {
            finalQuality = 'auto:good'
        }
        if (width <= 400) {
            finalQuality = 'auto:eco'
        }
        if (width <= 200) {
            finalQuality = 'auto:low'
        }
    }

    const modifiers = [
        `c_${crop}`,
        `g_${gravity}`,
        `q_${finalQuality}`,
        `w_${width}`,
        'f_auto',
    ]
    if (effect) {
        modifiers.push(`e_${effect}`)
    }
    if (height) {
        modifiers.push(`h_${height}`)
    }
    if (zoom) {
        modifiers.push(`z_${zoom}`)
    }
    const isOld = src.includes(oldFixedPath)
    const path = `${fixedPath}${IMAGE_FIXED_PATH}`
    let pathToSplit = `${fixedPath}${IMAGE_FIXED_PATH}`
    if (isOld) {
        pathToSplit = `${oldFixedPath}${IMAGE_FIXED_PATH}`
    }
    const [, dynamicPart] = src.split(pathToSplit)
    return `${path}/${modifiers.join(',')}/${dynamicPart}`
}

export const createLoader =
    ({ maxWidth, maxHeight, ...other }) =>
    ({ src, height, width = 0 }) => {
        let finalWidth = maxWidth
        if (!maxWidth || maxWidth > width) {
            finalWidth = width
        }
        let finalHeight = maxHeight
        if (!maxHeight || maxHeight > height) {
            finalHeight = height
        }

        const props = {
            ...other,
            src,
            width: finalWidth,
            height: finalHeight,
        }
        return cloudinaryLoader(props)
    }

const Image = ({
    crop,
    zoom,
    width,
    height,
    gravity,
    filename,
    maxWidth,
    maxHeight,
    placeholder,
    showPlaceholder = true,
    ...props
}) => {
    if (props.src.includes('tripbuilder') && !showPlaceholder) {
        return <NextImage {...props} />
    }

    return (
        <NextImage
            loader={createLoader({
                crop,
                zoom,
                gravity,
                maxWidth,
                maxHeight,
            })}
            {...props}
            width={props.fill ? undefined : width}
            height={props.fill ? undefined : height}
            placeholder={placeholder?.type}
            blurDataURL={placeholder?.base64}
        />
    )
}

export default Image
