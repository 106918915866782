import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import { motion, AnimatePresence } from 'framer-motion'
import * as constants from '@connections/constants'
import HeadingDesktopWithState from './HeadingDesktopWithState'
import CarouselDots from '../utils/CarouselDots'
import { variant } from '../../util/index'

const {
    SIZE_SMALL: SMALL,
    SIZE_MEDIUM: MEDIUM,
    SIZE_LARGE: LARGE,
    SIZE_DEFAULT: DEFAULT,
    SIZE_FULL: FULL,
} = constants

const HeadingSectionDesktop = ({ content, activeHeading, onChangeHeading }) => {
    const { hasArc, size, maxSize, headings } = content
    const heading = headings[activeHeading]

    // Avoid crash when adding a new empty heading in the cms
    if (typeof heading === 'undefined') {
        return null
    }

    return (
        <SbEditable content={content}>
            <section
                className={clsx(
                    'hidden md:block relative w-full',
                    variant(size, {
                        [SMALL]: 'h-[50vh]',
                        [DEFAULT]: 'h-[75vh]',
                        [LARGE]: 'h-[85vh]',
                        [FULL]: 'h-screen',
                    }),
                    variant(maxSize, {
                        [MEDIUM]: 'max-h-[35rem]',
                    })
                )}
            >
                <AnimatePresence initial={false}>
                    <motion.div
                        key={activeHeading}
                        className="w-full h-full absolute"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                            opacity: { duration: 1 },
                        }}
                    >
                        <HeadingDesktopWithState
                            key={heading._uid}
                            content={heading}
                            hasArc={hasArc}
                            size={size}
                        />
                    </motion.div>
                </AnimatePresence>
                {headings.length > 1 && (
                    <div className="container absolute inset-0 overflow-hidden">
                        <CarouselDots
                            count={headings.length}
                            activeIndex={activeHeading}
                            onChangeIndex={onChangeHeading}
                            className="absolute top-0 right-0"
                        />
                    </div>
                )}
            </section>
        </SbEditable>
    )
}
export default HeadingSectionDesktop
