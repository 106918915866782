import React from 'react'
import MapColumn from './MapColumn'
import TabsColumn from './TabsColumn'
import Placeholder from './Placeholder'
import QuoteColumn from './QuoteColumn'
import ImageColumn from './ImageColumn'
import VideoColumn from './VideoColumn'
import BannerColumn from './BannerColumn'
import ContentColumn from './ContentColumn'
import BulletsColumn from './BulletsColumn'
import ProductColumn from './ProductColumn'
import ImageGroupColumn from './ImageGroupColumn'

const Components = {
    MapColumn,
    TabsColumn,
    ImageColumn,
    QuoteColumn,
    VideoColumn,
    BannerColumn,
    ContentColumn,
    BulletsColumn,
    ProductColumn,
    ImageGroupColumn,
}

const DynamicColumn = ({
    content,
    ...props
}) => {
    const Component = Components[content.component]
    if (Component) {
        return (
            <Component content={content} {...props} />
        )
    }
    return <Placeholder componentName={content.component} />
}

export default DynamicColumn
