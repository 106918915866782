import React from 'react'
import SbEditable from 'storyblok-react'
import useStoryblokBridge from '../../hooks/useStoryblokBridge'
import ShopPage from './ShopPage'

const ShopPageWithState = () => {
    const { content } = useStoryblokBridge()

    return (
        <SbEditable content={content}>
            <ShopPage {...content} />
        </SbEditable>
    )
}

export default ShopPageWithState
