import React from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import { Trans } from '@lingui/react'
import SbEditable from 'storyblok-react'
import { getEntityContentOverrides } from '../../util/entities'
import Image from '../utils/Image'
import CustomText from '../utils/CustomText'
import Button, { VARIANT_BLUE_OUTLINED } from '../utils/Button'
import usePathnameLocale from '../hooks/usePathnameLocale'

const BlogPostCard = ({
    content,
    columnWidth,
    className,
}) => {
    const {
        subject,
        buttonText,
        blogPost,
        openInNewTab,
    } = content
    const locale = usePathnameLocale()
    const {
        href,
        image,
        title,
        description,
    } = getEntityContentOverrides(content, blogPost, locale)
    return (
        <SbEditable content={content}>
            <div className={className}>
                <div
                    className={clsx(
                        'block relative',
                        columnWidth > 1 && 'md:min-h-[576px] md:h-full md:bg-gray-200 md:rounded-lg',
                    )}
                >
                    <div
                        className={clsx(
                            'relative rounded-lg bg-gray-200 h-[171px]',
                            columnWidth === 1 && 'md:h-[386px]',
                            columnWidth > 1 && 'md:h-full',
                        )}
                    >
                        {image?.filename && (
                            <Image
                                fill
                                quality={80}
                                maxWidth={750}
                                {...image}
                                draggable={false}
                                src={image.filename}
                                className="rounded-lg object-cover"
                            />
                        )}
                    </div>
                    <div
                        className={clsx(
                            'mt-3',
                            columnWidth === 1 && 'bg-transparent',
                            columnWidth > 1 && 'md:absolute md:right-0 md:bottom-0 bg-white md:max-w-sm md:rounded-tl-lg md:pl-8 md:pt-7'
                        )}
                    >
                        {subject && (
                            <span className="text-xs text-gray-600 uppercase font-medium">
                                {subject}
                            </span>
                        )}
                        {title && (
                            <CustomText
                                as="h3"
                                className="text-black text-2xl font-medium mt-2"
                                customText={title}
                            />
                        )}
                        {description && (
                            <p className="text-sm text-black font-normal mt-2 line-clamp-4">
                                {description}
                            </p>
                        )}
                        {href && (
                            <Link
                                href={href}
                                draggable={false}
                                target={openInNewTab ? '_blank' : undefined}
                            >
                                <Button
                                    className="mt-7"
                                    variant={VARIANT_BLUE_OUTLINED}
                                >
                                    {buttonText || <Trans id="Read article" />}
                                </Button>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </SbEditable>
    )
}

export default BlogPostCard
