import { getFullLocale } from '@connections/utils'

export const splitCodes = (codes = '') =>
    codes
        .split(',')
        .map((code) => code.trim())
        .filter((code) => !!code)

export const formatFullSlug = (fullSlug = null, options = {}) => {
    const { anchor = null } = options
    const locale = getFullLocale(options.locale)
    if (fullSlug === null) {
        return `/${locale}`
    }
    let newUrl = fullSlug
    if (fullSlug.endsWith('/')) {
        newUrl = fullSlug.slice(0, -1)
    }
    if (locale === 'fr-be' && fullSlug.includes('fr/')) {
        newUrl = newUrl.replace('fr/', 'fr-be/')
    }
    if (fullSlug.startsWith('/')) {
        newUrl = newUrl.slice(1)
    }
    if (!newUrl.includes(locale)) {
        newUrl = `${locale}/${newUrl}`
    }
    return `/${newUrl}${anchor !== null ? `#${anchor}` : ''}`
}

export const getHrefFromLink = (link = null, urlLocale) => {
    const locale = getFullLocale(urlLocale)
    const root = `/${locale}`
    if (link === null) {
        return root
    }
    if (link.linktype === 'url') {
        return link.url || root
    }
    const { story = null, anchor = null } = link
    if (story === null) {
        return root
    }
    return formatFullSlug(story.full_slug, { anchor, locale })
}

export const variant = (prop, variants, otherwise) => {
    if (variants[prop]) {
        return variants[prop]
    }
    return otherwise
}

export const cleanPhone = (phone) => {
    if (typeof phone !== 'string') {
        return ''
    }
    return phone.replace(' ', '').replace('(', '').replace(')', '')
}

export const logGraphqlErrors = ({ networkError, graphQLErrors }) => {
    let errors = []
    if (networkError) {
        const { result, bodyText } = networkError
        if (result) {
            errors = result.errors
        }
        if (bodyText) {
            errors = [
                {
                    message: bodyText,
                },
            ]
        }
    }
    if (graphQLErrors && graphQLErrors.length > 0) {
        errors = graphQLErrors
    }
    // eslint-disable-next-line no-console
    console.warn(errors)
}

export const getStoryBlokImageDimensions = (url) => ({
    width: url.split('/')[5].split('x')[0],
    height: url.split('/')[5].split('x')[1],
})

export const joinWithoutEmpty = (arr, join) => arr.filter((x) => x).join(join)

export const roundToX = (num, X) => +`${Math.round(`${num}e${X}`)}e-${X}`

export const isClient = typeof window === 'object'

/**
 * Determine if the browser is on a mobile device or pc.
 * This function returns true if it's a mobile browser.
 *
 * @returns {Boolean}
 */

export function isMobileBrowser() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    )
}

export const combineRefs =
    (refs = []) =>
    (el) => {
        refs.forEach((ref) => {
            if (typeof ref === 'function') {
                ref(el)
            } else {
                // eslint-disable-next-line no-param-reassign
                ref.current = el
            }
        })
    }

export const modifyEditable = (story) => {
    if (story.content._editable === null) {
        // eslint-disable-next-line no-param-reassign
        delete story.content._editable
    }
    return story
}

export const getStaticUrl = (path, parameters) =>
    `${process.env.NEXT_PUBLIC_CLOUDINARY_FIXED_PATH}/image/upload/${
        parameters ? `${parameters}/` : ''
    }Static/${path}`

export const filterEmptyValues = (value) =>
    value !== null && typeof value !== 'undefined'

export const getUniqueObject = (arr, selector = (item) => item) => {
    const map = new Map()
    arr.forEach((item) => {
        const prop = selector(item)
        if (!map.has(prop)) map.set(prop, item)
    })
    return [...map.values()]
}

export const arrayContains = (arr1 = [], arr2 = []) =>
    arr1.some((value) => arr2.includes(value))

export const removeTrailingSlash = (url) => url.replace(/\/$/, '')
