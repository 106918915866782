import React from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import { Trans } from '@lingui/react'
import Icon from '../utils/Icon'
import Image from '../utils/Image'
import Price from '../utils/Price'
import CustomText from '../utils/CustomText'
import Button, { VARIANT_WHITE_NO_BACKGROUND } from '../utils/Button'

const DestinationCard = ({
    price,
    hideGradient,
    buttonText,
    href,
    image,
    title,
    description,
    openInNewTab,
    className,
    ...props
}) => (
    <Link
        href={href}
        draggable={false}
        target={openInNewTab ? '_blank' : undefined}
        className={
            clsx(
                'group block relative h-[350px] bg-gray-100 rounded-lg',
                'md:h-[475px]',
                className,
            )
        }
        {...props}
    >
        {image?.filename && (
            <Image
                fill
                quality={80}
                maxWidth={750}
                {...image}
                draggable={false}
                src={image.filename}
                className="rounded-lg group-hover:opacity-80 object-cover"
            />
        )}
        {href && (
            <div className="flex justify-center items-center absolute left-6 top-4 bg-white bg-opacity-60 h-10 w-10 rounded-full">
                <Icon
                    name="arrow-up-right"
                    size="smaller"
                    color="blue-bright"
                />
            </div>
        )}
        <div className="absolute bottom-8 inset-x-6 z-1">
            {title && (
                <CustomText
                    as="h3"
                    className="text-white text-2xl font-medium"
                    customText={title}
                />
            )}
            {description && (
                <p className="text-white text-sm mt-2 line-clamp-4">
                    {description}
                </p>
            )}
            <div className="flex justify-between items-center mt-6">
                {href && (
                    <Button
                        as="div"
                        variant={VARIANT_WHITE_NO_BACKGROUND}
                        iconAfterName="arrow-right"
                        className="px-0"
                    >
                        {buttonText || <Trans id="Discover" />}
                    </Button>
                )}
                {price !== null && (
                    <span className="text-white text-sm">
                        <Price price={price} />
                    </span>
                )}
            </div>
        </div>
        {!hideGradient && (
            <div className="absolute bottom-0 inset-x-0 rounded-b-lg h-3/4 card-gradient" />
        )}
    </Link>
)

export default DestinationCard
